const resourcesChildren = [
  {
    label: 'Calculator',
    path: '/free-settlement-agreement-calculator',
  },
  {
    label: 'Templates',
    path: '/templates',
  },
  {
    label: 'Q&As',
    path: '/faqs',
  },
  {
    label: 'Do I Have A Case',
    path: '/do-i-have-a-case',
  },
  {
    label: 'Sitemap',
    path: '/sitemap',
  },
  {
    label: 'Blog',
    path: '/blog',
  },
];

export default resourcesChildren;
