const AboutMenu = ( {data} ) => {
  const menuData = data?.data?.menu?.menuItems?.nodes;
  return (
    <div className="about-menu">
      <div className="about-menu__container">
        <div className="about-menu__topic-container">
          {menuData.map((menuVoice) => (
            <a href={menuVoice.path}>{menuVoice.label}</a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutMenu;
