import GuidesMenu from './GuidesMenu';
import SelfhelpMenu from './SelfhelpMenu';
import AboutMenu from './AboutMenu';

const Menus = ({ menu, menuData, aboutMenuData, toolsMenuData }) => {
  switch (menu) {
    case 'Guides':
      return <GuidesMenu data={menuData} />;
    case 'Self help':
      return <SelfhelpMenu data={toolsMenuData} />;
    case 'About':
      return <AboutMenu data={aboutMenuData} />;

    default:
      return null;
  }
};

export default Menus;
