import NextHead from 'next/head';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Schema from '../Schema';
import defaultSchema from '../Schema/defaultSchema';

const Head = ({ yoastSeo, seo, schema, pageSchemaSet, isSidebarHidden, indexStatus }) => {
  const [isParamUrl, setIsParamUrl] = useState(false);
  const yoastSchema = yoastSeo?.schema;
  const schemaRaw = !isSidebarHidden ? pageSchemaSet : yoastSeo?.schema;
  const finalSchema = schema || yoastSchema || defaultSchema;
  const finalTitle =
    yoastSeo?.title ||
    seo?.title ||
    'Employment Law Solicitors London & Nationwide | Monaco Solicitors';
  const finalDescription =
    seo?.description ||
    yoastSeo?.metaDesc ||
    'We specialise in negotiating fair settlement agreement exit packages for employees when they have been treated badly at work. Also employment tribunals.';
  const router = useRouter();
  const canonicalUrl =
    router.pathname === '/homepage'
      ? 'https://www.monacosolicitors.co.uk'
      : (`https://www.monacosolicitors.co.uk` + (router.asPath === '/' ? '' : router.asPath)).split(
          '?',
        )[0];
  useEffect(() => {
    const url = window.location.href;
    setIsParamUrl(url.includes('?'));
  }, []);
  const indexPage = indexStatus?.indexPage;
  let finalRobots = isParamUrl || !indexPage ? 'noindex, nofollow' : 'index, follow';
  if (process.env.NEXT_PUBLIC_GQL_URL === 'https://mscms02.wpengine.com/graphql') {
    finalRobots = 'nofollow, noindex';
  }

  return (
    <>
      <NextHead>
        <title>{finalTitle}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="description" content={finalDescription} />
        <meta name="title" content={finalTitle} />
        <meta key="robots" name="robots" content={finalRobots} />
        <meta key="googlebot" name="googlebot" content={finalRobots} />

        {/* Open graph */}
        <meta property="og:description" content={finalDescription} />
        <meta property="fb:app_id" content="Monaco Solicitors" />
        {/* twitter cards */}

        <meta property="twitter:card" content="summary" />
        <meta property="twitter:title" content={finalTitle} />
        <meta property="twitter:description" content={finalDescription} />
        <meta property="twitter:image" content="#" />
        <meta property="twitter:url" content="#" />
        <meta
          name="google-site-verification"
          content="YUP68krJ9mPC9oANdae0FWcTcV3bTxffGDeG7TNUGR4"
        />

        <link rel="canonical" href={canonicalUrl} />

        {/* favicons */}
        <link rel="apple-touch-icon" sizes="57x57" href="/favicon/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="/favicon/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="/favicon/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="/favicon/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="/favicon/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="/favicon/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="/favicon/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="/favicon/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-icon-180x180.png" />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/favicon/android-icon-192x192.png"
        />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/favicon/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/favicon/manifest.json" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700&family=Poppins:wght@200;300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
        <script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-T5XNDHQ')`,
          }}
        ></script>
        <script
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
            window.GUMLET_CONFIG = {
              hosts: [{
                  current: "monacosolicitors.co.uk",
                  gumlet: "monacosolicitors.gumlet.io"
              }],
              lazy_load: true,
              srcset: true,
              auto_webp: true
            };
            (function(){d=document;s=d.createElement("script");s.src="https://cdn.jsdelivr.net/npm/gumlet.js@2.1/dist/gumlet.min.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`,
          }}
        ></script>
        <script
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
              window.markerConfig = {
                project: '654b8e4c622f4e8873227f40',
                source: 'snippet'
              };
              !function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);
            `,
          }}
        ></script>
        {/* Fonts */}
      </NextHead>
      {finalSchema && <Schema schema={finalSchema} schemaRaw={schemaRaw} />}
    </>
  );
};

export default Head;
