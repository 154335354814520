import { useRouter } from 'next/router';
import { useState } from 'react';

import Menus from '../Menus';
import Search from '../Search';

const Header = ({ menuData, aboutMenuData, toolsMenuData, showMenu }) => {
  const [open, setOpen] = useState(false);
  const [menuName, setMenuName] = useState();
  const [isSearch, setIsSearch] = useState(false);
  const hideMenu = true;
  const displayMenu = showMenu === undefined ? hideMenu : showMenu;
  const router = useRouter();
  const redirectToHome = () => {
    router.push({
      pathname: '/',
    });
  };

  const handleMenuOnHover = (target) => {
    setOpen(true);
    setMenuName(target);
  };

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const now = new Date();
  const day = days[now.getDay()];

  return (
    <header className="header">
      {open && (
        <div
          onMouseLeave={() => {
            setOpen(false);
          }}
        >
          <Menus
            menu={menuName}
            menuData={menuData}
            aboutMenuData={aboutMenuData}
            toolsMenuData={toolsMenuData}
          />
        </div>
      )}
      <div className="header__container">
        <div className="header__ms__wrapper">
          <a href="/">
            <div className="header__logo" data-bg="/images/ms-logo.png" />
          </a>
        </div>
        {!isSearch && displayMenu && (
          <nav className="header__nav">
            <ul>
              <li>
                <p
                  onMouseOver={({ target }) => {
                    handleMenuOnHover(target.innerText);
                  }}
                >
                  Guides
                </p>
              </li>
              <li>
                <a href="/free-settlement-agreement-calculator">Calculator</a>
              </li>
              <li>
                <a href="/employment-law-solicitors">Services</a>
              </li>
              <li>
                <p
                  onMouseOver={({ target }) => {
                    handleMenuOnHover(target.innerText);
                  }}
                >
                  Self help
                </p>
              </li>
              <li>
                <p
                  onMouseOver={({ target }) => {
                    handleMenuOnHover(target.innerText);
                  }}
                >
                  About
                </p>
              </li>
            </ul>
          </nav>
        )}
        <div className="header__container__right">
          {!isSearch && displayMenu && (
            <>
              <div
                className="header__container__right__search"
                onClick={() => {
                  setIsSearch(true);
                }}
              >
                <img
                  src="/images/search-icon-desktop.svg"
                  alt="search-icon-desktop"
                  width="20"
                  height="20"
                />
                <span>Search</span>
              </div>
              <div className="header__contact">
                <a href="/contact-us" className="button">
                  {day === 'Saturday' || day === 'Sunday' ? 'Get started' : 'Contact us'}
                </a>
              </div>
            </>
          )}
          {isSearch && (
            <img
              src="/images/close-icon-dark.svg"
              alt="close-icon-dark"
              width="16"
              height="16"
              onClick={() => {
                setIsSearch(false);
              }}
            />
          )}
          {!displayMenu && (
            <img
              src="/images/close-icon-dark.svg"
              alt="close-icon-dark"
              width="16"
              height="16"
              onClick={redirectToHome}
            />
          )}
        </div>
      </div>
      {isSearch && (
        <div className="header__search">
          <Search />
          <div className="header__search__popular">
            <p>Popular searches</p>
            <a href="/do-i-have-a-case">Do I have a case?</a>
            <a href="/constructive-dismissal">Constructive dismissal guide</a>
            <a href="/settlement-agreements">Settlement agreement guide</a>
            <a href="/employment-law">Employment law guide</a>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
