const SelfhelpMenu = ({data}) => {
  const menuData = data?.data?.menu?.menuItems?.nodes;
  return (
    <div className="selfhelp-menu">
      <div className="selfhelp-menu__container">
        <div className="selfhelp-menu__topic-container">
          {menuData.map((menuVoice) => (
            <a href={menuVoice.path}>{menuVoice.label}</a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelfhelpMenu;
