import { useState } from 'react';
import { ReactComponent as BackIcon } from './../../../public/images/back-icon.svg';

import TopicMenu from './TopicMenu';

const MobileGuidesMenu = ({ data }) => {
  const [menuTopic, setMenuTopic] = useState();
  const [isShown, setIsShown] = useState(false);

  const handleMenuVoiceOnClick = (topic) => {
    setMenuTopic(topic);
    setIsShown(true);
  };

  const menuData = data?.data?.menu?.menuItems?.nodes.filter(
    (menuItem) => menuItem.childItems.nodes.length > 0,
  );

  return (
    <>
      {isShown && (
        <>
          <div
            className="header-mobile__navigaton__controls__back_submenu"
            onClick={() => {
              setIsShown(false);
            } }
          >
            <BackIcon />
          </div>
          <div className="header-mobile__navigaton__sub-submenu-title">
            {menuTopic.label}
          </div>
        </>
      )}
      <div className="guides-menu">
        {isShown && (
          <div>
            <TopicMenu topic={menuTopic} />
          </div>
        )}
        <div className="guides-menu__container">
          {menuData.map((menuVoice) => (
            <div
            className="guides-menu__topic-container"
            onClick={() => {
              handleMenuVoiceOnClick(menuVoice);
            }}>
              <p>
                {menuVoice.label}
              </p>
              <BackIcon />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MobileGuidesMenu;
