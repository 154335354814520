import clsx from 'clsx';
import { useState } from 'react';
import { useRouter } from 'next/router';

import CDF from '../Sections/CDF';
import Search from '../Search';
import MobileMenu from '../MobileMenu';

import { ReactComponent as BurgerMenu } from './../../public/images/burger-mobile.svg';
import { ReactComponent as CloseIcon } from './../../public/images/close-icon.svg';
import { ReactComponent as BackIcon } from './../../public/images/back-icon.svg';

const HeaderMobile = ({ menuData, aboutMenuData, toolsMenuData, showMenu }) => {
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuName, setMenuName] = useState(null);
  const [isAboutOpen, setAboutOpen] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const hideMenu = true;
  const displayMenu = showMenu === undefined ? hideMenu : showMenu;
  const router = useRouter();
  const redirectToHome = () => {
    router.push({
      pathname: '/',
    });
  };

  const classes = clsx('header-mobile', {
    'header-mobile--menu-open': isMenuOpen,
  });

  const handleMenuVoiceOnClick = (target) => {
    setMenuName(target.innerText);
    setIsMenuOpen(true);
  };

  return (
    <div className={classes}>
      {isBurgerOpen && (
        <div className="header-mobile__navigation">
          <div className="header-mobile__navigaton__controls">
            {isMenuOpen && (
              <>
                <div
                  onClick={() => {
                    setIsMenuOpen(false);
                  }}
                >
                  <BackIcon />
                </div>
                <div className="header-mobile__navigaton__submenu-title">{menuName}</div>
                <div
                  className="header-mobile__navigation__close"
                  onClick={() => {
                    setIsBurgerOpen(false);
                  }}
                >
                  <CloseIcon />
                </div>
              </>
            )}
          </div>
          <div className="header-mobile__navigation__controls-submenu">
            {!isMenuOpen && (
              <>
                <div className="header-mobile__navigaton__title">Menu</div>
                <div
                  className="header-mobile__navigation__close__submenu"
                  onClick={() => {
                    setIsBurgerOpen(false);
                  }}
                >
                  <CloseIcon />
                </div>
              </>
            )}
          </div>

          {isMenuOpen && (
            <MobileMenu
              menu={menuName}
              menuData={menuData}
              toolsMenuData={toolsMenuData}
              aboutMenuData={aboutMenuData}
            />
          )}

          <div className="header-mobile__navigation__top">
            <ul>
              <li>
                <div
                  onClick={({ target }) => {
                    handleMenuVoiceOnClick(target);
                  }}
                >
                  Guides
                </div>
                <BackIcon />
              </li>
              <li>
                <a href="/free-settlement-agreement-calculator">Calculator</a>
              </li>
              <li>
                <a href="/employment-law-solicitors">Services</a>
              </li>
              <li>
                <div
                  onClick={({ target }) => {
                    handleMenuVoiceOnClick(target);
                  }}
                >
                  Self help
                </div>
                <BackIcon />
              </li>
            </ul>
          </div>
          <div className="header-mobile__navigation__bottom">
            <ul>
              <li>
                <div
                  onClick={({ target }) => {
                    handleMenuVoiceOnClick(target);
                  }}
                >
                  About
                </div>
                <BackIcon />
              </li>
              <li>
                <a href="/contact-us">Contact Us</a>
              </li>
            </ul>
          </div>
          <div className="menu__cdf">
            <CDF classNames={'calculator--dark'} sideBar contactFieldLayout={false} />
          </div>
        </div>
      )}

      <div className="header-mobile__wrapper">
        <div className="header-mobile-burger">
          {!isSearch && displayMenu && (
            <BurgerMenu
              onClick={() => {
                setIsBurgerOpen(true);
              }}
            />
          )}
          {!isSearch && displayMenu && (
            <div
              className="header-mobile__right__search"
              onClick={() => {
                setIsSearch(true);
              }}
            >
              <img
                src="/images/search-icon-mobile.svg"
                alt="search-icon-mobile"
                width="33"
                height="33"
              />
            </div>
          )}
        </div>
        <a href="/">
          <div className="header-mobile__logo" data-bg="/images/ms-logo.png" />
        </a>
        <div className="header-mobile__right">
          {!isSearch && displayMenu && (
            <a href="/contact-us" className="contact-button">
              Contact Us
              {/* <div className="header-mobile_right__phone" data-bg="/images/phone-icon.svg" /> */}
            </a>
          )}
          {isSearch && (
            <img
              src="/images/close-icon-dark.svg"
              alt="close-icon-dark"
              width="16"
              height="16"
              onClick={() => {
                setIsSearch(false);
              }}
            />
          )}
          {!displayMenu && (
            <img
              src="/images/close-icon-dark.svg"
              alt="close-icon-dark"
              width="16"
              height="16"
              onClick={redirectToHome}
            />
          )}
        </div>
      </div>
      {isSearch && (
        <div className="header__search">
          <Search />
          <div className="header__search__popular">
            <p>Popular searches</p>
            <a href="/search?s=constructive%20dismissal">Constructive dismissal</a>
            <a href="/search?s=coronavirus">Coronavirus</a>
            <a href="/search?s=health+%26+safety">Health & safety</a>
            <a href="/search?s=grievances">Grievances</a>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderMobile;
