/**
 * This function is for Rota's `/create-deal` endpoint which in versions
 * prior to 1.0.2 returns two json objects back to back.
 * (Here we are only parsing the first json object)
 */
export const legacyParseFirstJsonInResponse = (axiosResponse) => {
    if (typeof axiosResponse.data !== 'string') {
        return axiosResponse;
    }

    try {
      const jsonParts = axiosResponse.data.split(/(?<=})/);
      axiosResponse.data = JSON.parse(jsonParts[0]);
    } catch (e) {
      console.error(e);
    }

    return axiosResponse;
};
