const setTrackingParams = (query, referrer = document.referrer) => {
  if (typeof window !== 'undefined') {
    const msTrackingParams = JSON.parse(localStorage.getItem('ms_tracking_params'));

    if (!msTrackingParams) {
      const landingPage = query.split('?')[0];
      const queryString = query.split('?')[1];

      let paramsArray = [];

      if (queryString) {
        queryString.split('&').forEach((param) => {
          const [key, value] = param.split('=');
          if (key.startsWith('utm_') || key === 'gclid') {
            paramsArray.push({ key, value: decodeURIComponent(value) });
          }
        });
      }

      if (paramsArray.length === 0) {
        let utmMedium = 'direct';
        if (referrer) {
          if (referrer.includes('google.com') || referrer.includes('bing.com')) {
            utmMedium = 'organic';
          } else {
            utmMedium = 'referral';
          }
        }

        paramsArray = [
          { key: 'utm_source', value: referrer ? new URL(referrer).hostname : '' },
          { key: 'utm_medium', value: utmMedium },
          { key: 'utm_campaign', value: '' },
          { key: 'utm_term', value: '' },
        ];
      }

      paramsArray.push({ key: 'landing-page', value: landingPage });

      localStorage.setItem('ms_tracking_params', JSON.stringify(paramsArray));
    }
  }
};

export default setTrackingParams;
