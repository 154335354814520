import { Formik, Form, Field } from 'formik';
import { useRouter } from 'next/router';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  search: Yup.string().required('Required'),
});

const Search = (props) => {
  const router = useRouter();
  const handleFormSubmit = async (values) => {
    const { search } = values;
    router.push({
      pathname: '/search',
      query: { s: search },
    });
  };

  return (
    <div className="search">
      <Formik
        initialValues={{
          search: props.searchTerm || '',
        }}
        onSubmit={handleFormSubmit}
        validationSchema={validationSchema}
      >
        {({ isValid }) => (
          <Form className="search__form">
            <img
              className="search__form__search-icon"
              src="/images/search-icon-desktop.svg"
              alt="search-icon-desktop"
              width="20"
              height="20"
            />
            <Field type="text" name="search" placeholder="Search" />
            <button className="button" disabled={!isValid} type="submit">
              Search
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Search;
