const defaultSchema = [
  {
     "@context":"https://schema.org",
     "@type":"Organization",
     "@id":"https://www.monacosolicitors.co.uk/#organization",
     "name":"Monaco Solicitors",
     "url":"https://www.monacosolicitors.co.uk/",
     "sameAs":[
        "https://www.facebook.com/MonacoSolicitors/",
        "https://www.linkedin.com/company/10169024/",
        "https://www.youtube.com/user/CompromiseAgreements",
        "https://twitter.com/monacosolicitor"
     ],
     "logo":{
        "@type":"ImageObject",
        "@id":"https://www.monacosolicitors.co.uk/#logo",
        "url":"https://www.monacosolicitors.co.uk/app/uploads/2019/07/monaco-solicitors-logo.png"
     }
  }
]

export default defaultSchema;
