const SelfhelpMenu = () => {
  return (
    <div className="selfhelp-menu">
      <div className="selfhelp-menu__container">
        <div className="selfhelp-menu__topic-container">
          <a href="/free-settlement-agreement-calculator/">Calculator</a>
          <a href="/do-i-have-a-case">Do I Have A Case</a>
          <a href="/no-win-no-fee">No Win No Fee</a>
          <a href="https://monacosolicitors.grapple.uk/">Letter Builder</a>
          <a href="/templates">Templates</a>
          <a href="/blog">Blogs</a>
        </div>
      </div>
    </div>
  );
};

export default SelfhelpMenu;
