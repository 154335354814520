const getTrackingParams = () => {
  if (typeof window !== 'undefined') {
    try {
      const trackingParamsArray = JSON.parse(localStorage.getItem('ms_tracking_params'));

      const trackingParams = {};

      if (trackingParamsArray) {
        trackingParamsArray.forEach((param) => (trackingParams[param.key] = param.value));
      }
      return trackingParams;
    } catch (e) {
      console.error("getTrackingParams() failed", e);
    }
  }
  return null;
};

export default getTrackingParams;
