import MobileGuidesMenu from './MobileGuidesMenu';
import SelfhelpMenu from '../MobileMenu/MobileSelfHelp';
import AboutMenu from '../MobileMenu/MobileAboutUsMenu';

const MobileMenu = ({ menu, menuData, toolsMenuData, aboutMenuData }) => {
  switch (menu) {
    case 'Guides':
      return <MobileGuidesMenu data={menuData} />;
    case 'Self help':
      return <SelfhelpMenu data={toolsMenuData} />;
    case 'About':
      return <AboutMenu data={aboutMenuData}/>;

    default:
      return null;
  }
};

export default MobileMenu;
